import { computed, observable } from 'mobx'
import { InsightVM } from './InsightVM'

export class InsightFrameVM {
  constructor(insights: InsightVM[], frame: string) {
    this.insights = insights
    this.frame = frame
  }

  @observable public insights: InsightVM[] = []
  @observable public frame: string = ''

  @computed
  public get title(): string {
    if (this.frame === 'Individual') return 'Personal Framing'
    if (this.frame === 'Organization') return 'Operational Framing'
    if (this.frame === 'Team') return 'Relational Framing'
  }

  @computed
  private get hasInsights(): boolean {
    return this.insights && this.insights.length > 0
  }

  public get framingScore(): number {
    let score = undefined
    if (this.hasInsights) {
      const orgArray = this.insights.filter((insight) => insight.Frame === this.frame)
      if (orgArray.length > 0) {
        score = orgArray[0].SentimentScore
      }
    }
    return score
  }

  public get framingDirective(): string {
    let directive = undefined
    if (this.hasInsights) {
      const orgArray = this.insights.filter((insight) => insight.Frame === this.frame)
      if (orgArray.length > 0) {
        directive = orgArray[0].Directive
      }
    }
    return directive
  }

  @computed
  public get framingInsights(): string[] {
    if (this.hasInsights) {
      return this.insights
        .filter((insight) => insight.Frame === this.frame)
        .map((insight) => insight.Insight)
    }
    return []
  }

  @computed
  public get hasFramingInsights(): boolean {
    let array = []
    if (this.hasInsights) {
      array = this.insights.filter((insight) => insight.Frame === this.frame)
    }
    return array.length > 0
  }
}
